










































import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  data: () => ({
    showEmail: false,
    email: 'anthon.kendel@gmail.com',
  }),
  methods: {
    onShowEmail(): void {
      this.showEmail = true;
      setTimeout(() => (this.showEmail = false), 10000);
    },
  },
});
